import { useEffect, useRef, useState } from 'react'
import {
  ChimeJoinInfo,
  Meeting,
  MeetingRaw,
  MeetingTextEntry,
  Participant,
  ParticipantWithToken,
  Room,
  SocketMessage,
} from './models'
import { BehaviorSubject } from 'rxjs'
import { OngoingRecording, videoMimeType } from '../utils/recording'
import {
  s3StreamedUpload,
  s3StreamedUploadFromExisting,
  VideoUploading,
} from '../utils/aws-upload'
import { take } from 'rxjs/operators'

export class ReconnectingWebSocket {
  url: string
  stayConnected = true
  websocket: WebSocket
  backOff = 1000
  open = false

  onclose: ((ev: CloseEvent) => unknown) | null
  onerror: ((ev: Event) => unknown) | null
  onmessage: ((ev: MessageEvent) => unknown) | null
  onopen: ((ev: Event) => unknown) | null

  closeListener = (ev: Event) => {
    this.close()
  }

  constructor(url: string) {
    this.url = url
    window.addEventListener('beforeunload', this.closeListener)
  }

  ready(): void {
    if (!this.websocket) {
      this.reconnect()
    }
  }

  reconnect(): void {
    console.log(`URL is ${this.url}`)
    this.websocket = new WebSocket(this.url)
    const me = this
    this.websocket.onclose = (ev: CloseEvent): void => {
      this.open = false
      if (me.onclose) me.onclose(ev)
      if (this.stayConnected) {
        window.setTimeout(() => {
          this.reconnect()
        }, this.backOff)
        this.backOff *= 2
        if (this.backOff > 60000) this.backOff = 60000
      }
    }
    this.websocket.onerror = (ev): unknown => this.onerror && this.onerror(ev)
    this.websocket.onmessage = (ev): unknown =>
      this.onmessage && this.onmessage(ev)
    this.websocket.onopen = (ev: Event): void => {
      if (me.onopen) me.onopen(ev)
      this.backOff = 1000
      this.open = true
    }
  }

  close(): void {
    window.removeEventListener('beforeunload', this.closeListener)
    this.stayConnected = false
    this.websocket.close(1000)
  }
}

export function useWaitingRoomControl(
  websocket: ReconnectingWebSocket,
  room: Room,
  onReady: (p: ParticipantWithToken) => void
): WaitingRoomControl {
  const rc = useRef<WaitingRoomControl>()
  const [, setRefresh] = useState({})
  if (rc.current === undefined)
    rc.current = new WaitingRoomControl(websocket, room, onReady)
  useEffect(() => {
    rc.current!.notify = (): void => setRefresh({})
    return (): void => {
      rc.current!.notify = (): void => {
        /* noop */
      }
    }
  })
  return rc.current!
}

export class WaitingRoomControl {
  participant: Participant | null = null
  token: string | null = null
  dead = false
  error: string | undefined = undefined
  started = false
  onReady: (p: ParticipantWithToken) => void
  notify: () => void

  constructor(
    socket: ReconnectingWebSocket,
    room: Room,
    onReady: (p: ParticipantWithToken) => void
  ) {
    this.onReady = onReady
    const me = this
    console.log('WaitingRoomControl is in charge of socket')
    socket.onopen = (): void => {
      console.log('Connected')
    }
    socket.onerror = (): void => {
      console.log('Dead')
    }
    socket.onmessage = (ev): void => {
      const message = JSON.parse(ev.data as string) as SocketMessage
      console.log('Got WRC message', message)
      switch (message.type) {
        case 'Error':
          this.error = message.data ?? '?'
          this.notify()
          break
        case 'Meeting':
          me.started = true
          break
        case 'ParticipantToken':
          me.token = message.data!
          const u = socket.url
          socket.url =
            u.substring(0, u.indexOf('?name')) + '?token=' + message.data!
          me.check()
          fetch(`/api/rooms/${room.id}/`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Participant-Authorization': me.token,
            },
          })
            .then(x => x.json())
            .then(x => {
              if (x.meeting) this.started = true
            })
          break
        case 'Participant':
          if (message.id !== undefined && message.id !== me.participant?.id) {
            //DIE
            me.dead = true
          } else if (message.data !== undefined) {
            const newParticipant = JSON.parse(message.data) as Participant
            console.log(
              'Set participant? ',
              newParticipant,
              ' vs ',
              me.participant
            )
            if (
              me.participant === null ||
              me.participant.id === newParticipant.id
            ) {
              console.log('Setting participant')
              me.participant = newParticipant
              me.check()
            }
          }
          break
      }
      me.notify()
    }
    socket.ready()
  }

  check(): void {
    console.log('Checking...', this.participant, this.token)
    if (
      this.participant !== null &&
      this.participant.authorized &&
      this.token !== null
    ) {
      console.log('Proceed!')
      this.onReady({
        ...this.participant!,
        token: this.token,
      })
    }
  }
}

export function useRoomControl(
  websocket: ReconnectingWebSocket,
  roomId: number,
  me: ParticipantWithToken
): RoomControl {
  const rc = useRef<RoomControl>()
  if (rc.current === undefined)
    rc.current = new RoomControl(websocket, roomId, me)
  return rc.current!
}

export class RoomControl {
  meetingTextEntries: BehaviorSubject<Array<MeetingTextEntry>> =
    new BehaviorSubject([])
  meetingTextEntriesRaw: Record<string, MeetingTextEntry> = {}
  meeting: BehaviorSubject<Meeting | null> = new BehaviorSubject(null)
  room: BehaviorSubject<Room | null> = new BehaviorSubject(null)
  participants: BehaviorSubject<Array<Participant>> = new BehaviorSubject([])
  participantsRaw: Record<string, Participant> = {}
  me: ParticipantWithToken
  roomId: number
  websocket: ReconnectingWebSocket

  constructor(
    websocket: ReconnectingWebSocket,
    roomId: number,
    me: ParticipantWithToken
  ) {
    this.websocket = websocket
    this.roomId = roomId
    this.me = me
    this.refresh()
    const self = this
    console.log('RoomControl is in charge of socket')
    websocket.onopen = (): void => {
      self.refresh()
    }
    websocket.onmessage = (ev): void => {
      const message = JSON.parse(ev.data as string) as SocketMessage
      console.log('Got RC message', message)
      switch (message.type) {
        case 'Meeting':
          if (!message.data) return
          self.meeting.next(
            Meeting.parse(JSON.parse(message.data!) as MeetingRaw)
          )
          break
        case 'Error':
          console.error(`Got error ${message.data ?? 'unknown'} from socket`)
          break
        case 'Participant':
          if (message.data) {
            const p = JSON.parse(message.data) as Participant
            self.participantsRaw[p.id] = p
          } else {
            delete self.participantsRaw[message.id!]
          }
          self.participants.next(Object.values(self.participantsRaw))
          break
        case 'Room':
          break
        case 'MeetingTextEntry':
          if (message.data) {
            const p = JSON.parse(message.data) as MeetingTextEntry
            self.meetingTextEntriesRaw[p.id] = p
          } else {
            delete self.meetingTextEntriesRaw[message.id!]
          }
          self.meetingTextEntries.next(
            Object.values(self.meetingTextEntriesRaw).sort(
              (a, b) => a.time_start - b.time_start
            )
          )
          break
      }
    }
  }

  async refresh(): Promise<any> {
    const toAwait: Array<Promise<any>> = []
    toAwait.push(
      fetch(`/api/participants/?room=${this.roomId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Participant-Authorization': this.me.token,
        },
      })
        .then(x => x.json())
        .then((participants: Array<Participant>) => {
          this.participantsRaw = {}
          for (const p of participants) {
            this.participantsRaw[p.id] = p
          }
          this.participants.next(Object.values(this.participantsRaw))
        })
    )
    const room: Room = await (
      await fetch(`/api/rooms/${this.roomId}/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Participant-Authorization': this.me.token,
        },
      })
    ).json()
    this.room.next(room)
    if (room.meeting) {
      toAwait.push(
        fetch(`/api/meetings/${room.meeting}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Participant-Authorization': this.me.token,
          },
        })
          .then(x => x.json())
          .then(x => this.meeting.next(x))
      )
      toAwait.push(
        fetch(`/api/meeting-text-entries/?meeting=${room.meeting}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Participant-Authorization': this.me.token,
          },
        })
          .then(x => x.json())
          .then((entries: Array<MeetingTextEntry>) => {
            this.meetingTextEntries.next(
              entries.sort((a, b) => a.time_start - b.time_start)
            )
          })
      )
    }

    for (const a of toAwait) {
      await a
    }
  }

  sendMessage(contents: string): Promise<MeetingTextEntry> {
    return fetch(`/api/meeting-text-entries/`, {
      method: 'POST',
      body: JSON.stringify({
        content: contents,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Participant-Authorization': this.me.token,
      },
    }).then(x => x.json())
  }

  authorize(participant: Participant): Promise<Participant> {
    return fetch(`/api/participants/${participant.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        authorized: true,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': document.getElementById('csrf')!.textContent!,
      },
      credentials: 'same-origin',
    }).then(x => x.json())
  }

  kick(participant: Participant): Promise<void> {
    return fetch(`/api/participants/${participant.id}/`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': document.getElementById('csrf')!.textContent!,
      },
      credentials: 'same-origin',
    }).then((): void => {
      /* noop */
    })
  }

  start(): Promise<ChimeJoinInfo> {
    return fetch(`/api/rooms/${this.roomId}/chime_start`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    }).then(x => x.json())
  }

  end(): Promise<void> {
    return fetch(`/api/rooms/${this.roomId}/chime_end`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    }).then(x => {
      if (Math.floor(x.status / 100) !== 2) {
        throw new Error('Request to terminate failed')
      }
      this.recording?.end()
    })
  }

  join(): Promise<ChimeJoinInfo> {
    return fetch(`/api/rooms/${this.roomId}/chime_join`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Participant-Authorization': this.me.token,
      },
    }).then(x => x.json())
  }

  recording?: OngoingRecording
  upload?: VideoUploading
  recordingSent = false

  onStream(stream: MediaStream): void {
    if(!this.recordingSent) {
      this.recordingSent = true
      this.sendMessage("I am recording this meeting.")
    }
    if (this.recording && stream !== this.recording.stream) {
      console.log('Continuing the recording')
      this.recording.changeSources(stream)
    }
    const meeting = this.meeting.value
    if (!this.upload && meeting) {
      console.log('Starting the recording and upload')
      const recording = new OngoingRecording(stream, videoMimeType)
      this.recording = recording
      recording.resume()
      const upload =
        meeting.multipart_upload_key && meeting.multipart_upload_id
          ? s3StreamedUploadFromExisting(
              meeting.multipart_upload_key,
              meeting.multipart_upload_id,
              recording.data
            )
          : s3StreamedUpload(
              recording.data,
              recording.mimeType,
              recording.extension
            )
      this.upload = upload
      upload.info.pipe(take(1)).subscribe({
        next: info => {
          const meeting = this.meeting.value!
          fetch(`/api/meetings/${meeting.id}/`, {
            method: 'PATCH',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-CSRFToken': document.getElementById('csrf')!.textContent!,
            },
            body: JSON.stringify({
              multipart_upload_key: info.Key,
              multipart_upload_id: info.UploadId,
            }),
          }).then(x => x.json())
        },
      })
      upload.fullUrl.pipe(take(1)).subscribe(x => {
        const meeting = this.meeting.value!
        return fetch(`/api/meetings/${meeting.id}/`, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': document.getElementById('csrf')!.textContent!,
          },
          body: JSON.stringify({
            recording_s3: x,
          }),
        }).then(() => {
          window.onbeforeunload = null
          window.location.href = '/meeting/' + meeting.guid
        })
      })
    }
  }
}
