import React, { ReactElement } from 'react'
import { Room } from '../../models/models'
import {
  ReconnectingWebSocket,
  RoomControl,
  useWaitingRoomControl,
} from '../../models/sync'
import MainVideoCall from './MainVideoCall'

import '../../../sass/video-call.scss'

function setParticipantToken(room: Room, token: string): void {
  window.localStorage.setItem('participant_token_time', Date.now().toString())
  window.localStorage.setItem('participant_token_room', room.id.toString())
  window.localStorage.setItem('participant_token', token)
}

const ConnectRoomSocket = (
  room: Room,
  socket: ReconnectingWebSocket,
  onReady: (c: RoomControl) => void
): React.FC =>
  function ConnectRoomSocketFC(): ReactElement {
    const control = useWaitingRoomControl(socket, room, p =>
      onReady(new RoomControl(socket, room.id, p))
    )
    if (control.token !== null) setParticipantToken(room, control.token)
    if (control.dead)
      return (
        <div className='video-call-state-container'>
          <h1 className='video-call-state'>You&apos;ve been rejected.</h1>
        </div>
      )
    else if (control.error !== undefined) return <p>{control.error ?? ''}</p>
    else if (control.started)
      return (
        <div className='video-call-state-container'>
          <h1 className='video-call-state'>Waiting to be let in</h1>
        </div>
      )
    else
      return (
        <div className='video-call-state-container'>
          <h1 className='video-call-state'>Waiting for host</h1>
        </div>
      )
  }

const JoiningChimeCall = (
  control: RoomControl,
  setView: (v: React.FC) => void
): React.FC => {
  control.join().then(x => {
    setView(MainVideoCall(control, x, false, setView))
  })
  return function JoingingChimeChallFC(): ReactElement {
    return (
      <div className='video-call-state-container'>
        <h1 className='video-call-state'>Joining the call...</h1>
      </div>
    )
  }
}

export const UnauthorizedStatus = (
  room: Room,
  socket: ReconnectingWebSocket,
  setView: (v: React.FC) => void
): React.FC =>
  ConnectRoomSocket(room, socket, c => setView(JoiningChimeCall(c, setView)))

const StartingChimeCall = (
  control: RoomControl,
  setView: (v: React.FC) => void
): React.FC => {
  control.start().then(x => {
    setView(MainVideoCall(control, x, true, setView))
  })
  return function StartingChimCallFC(): ReactElement {
    return (
      <div className='video-call-state-container'>
        <h1 className='video-call-state'>Starting the call...</h1>
      </div>
    )
  }
}

export const startingChimeCallA = (
  room: Room,
  socket: ReconnectingWebSocket,
  setView: (v: React.FC) => void
): React.FC =>
  ConnectRoomSocket(room, socket, c => setView(StartingChimeCall(c, setView)))

export default ConnectRoomSocket
