import React, { useState } from 'react'
import { from } from 'rxjs'
import { useRx } from '../utils/react-rxjs'
import { map, switchMap, take, zipAll } from 'rxjs/operators'
import {
  authenticateAws,
  s3UploadAtOnce,
  WholeVideoUploading,
} from '../utils/aws-upload'
import { ListingStatus, Meeting } from '../models/models'
import { render } from 'react-dom'
import { addMeetingToCollection } from './create'

export type MeetingUploadComponentProps = Record<string, unknown>
export const MeetingUploadComponent: React.FC<MeetingUploadComponentProps> =
  () => {
    const [saving] = useState(false)
    const [instances, setInstances] = useState<Array<MeetingUploadModel>>([])
    function saveButton(): React.ReactElement | null {
      const classes = 'btn btn-primary ms-3'
      if (instances.length == 0) return null
      if (saving) return <div className={`${classes} disabled`}>Saving...</div>
      return (
        <button className={classes} type='submit'>
          Submit
        </button>
      )
    }
    return (
      <form
        className='matchParent flexVertical'
        onSubmit={(event): void => {
          event.preventDefault()
          from(instances)
            .pipe(
              map(x => {
                return x.uploading.fullUrl.pipe(
                  take(1),
                  switchMap(url => {
                    return from(
                      fetch('/api/meetings/?text=true', {
                        method: 'POST',
                        body: JSON.stringify({
                          name: x.name,
                          recording_s3: url,
                          listing_status: (x.isPublic
                            ? ListingStatus.UNLISTED
                            : ListingStatus.PRIVATE
                          ).toString(),
                          will_transcribe: x.willTranscribe,
                          start_datetime: new Date(),
                          end_datetime: new Date(),
                        }),
                        credentials: 'same-origin',
                        headers: {
                          'X-CSRFToken':
                            document.getElementById('csrf')!.textContent!,
                          'Content-Type': 'application/json',
                        },
                      }).then(result => {
                        if (!result.ok) {
                          throw result.statusText
                        }
                        return result.json() as Promise<Meeting>
                      })
                    )
                  })
                )
              }),
              zipAll()
            )
            .subscribe({
              next: x => {
                const collectionId = window.location.href.includes('collection') ? window.location.href.split('collection=')[1] : null
                console.log("collectionId", collectionId)
                console.log("x[0].guid", x[0].guid)
                if (collectionId !== null && x[0].guid !== undefined) {
                  addMeetingToCollection(x[0].guid, collectionId).then(() => {
                    console.log("Added meeting to collection")
                    window.location.href = `/meetings/meeting/${x[0].guid}`
                  }).catch(e => {
                    console.error(e)
                    window.location.href =
                    x.length === 1 ? `/meetings/meeting/${x[0].guid}` : '/'
                  })
                } else {
                  window.location.href =
                    x.length === 1 ? `/meetings/meeting/${x[0].guid}` : '/'
                }
              },
              error: e => {
                console.error(e)
                alert(`Failed to save changes: ${e}`)
              },
            })
        }}
      >
        {instances.length > 0 ? (
          <table className='scrolling maybe w-100'>
            <tr>
              <th>Name</th>
              <th>Progress</th>
              <th>Transcribe?</th>
              <th>Public</th>
            </tr>
            {instances.map((x, index) => {
              return (
                <MeetingUploadInstanceComponent
                  key={index}
                  {...x}
                  setName={(x): void => {
                    instances[index].name = x
                    setInstances([...instances])
                  }}
                  setTranscribe={(x): void => {
                    instances[index].willTranscribe = x
                    setInstances([...instances])
                  }}
                  setPublic={(x): void => {
                    instances[index].isPublic = x
                    setInstances([...instances])
                  }}
                />
              )
            })}
          </table>
        ) : (
          <div className='flexVertical align-self-stretch'>
            <p>
              Click the button below to add recordings that you want to upload
              for transcription.
            </p>
            <p>You can add as many recordings as you&apos;d like.</p>
          </div>
        )}
        <div className='mt-4'>
          <button
            className='btn btn-primary align-self-center'
            type='button'
            onClick={(): void => {
              // file upload
              const element = document.createElement('input')
              element.type = 'file'
              element.multiple = true
              element.accept = 'video/*,audio/*'
              element.onchange = (ev): void => {
                const files = (ev.target as HTMLInputElement).files
                if (files) {
                  const newInstances = []
                  for (let i = 0; i < files.length; i++) {
                    const file = files.item(i)!
                    const extPos = file.name.indexOf('.')
                    const newInstance = {
                      uploading: s3UploadAtOnce(
                        file,
                        file.type,
                        extPos === -1 ? 'webm' : file.name.substring(extPos + 1)
                      ),
                      name: file.name,
                      isPublic: false,
                      willTranscribe: false,
                    }
                    newInstances.push(newInstance)
                  }
                  const allInstances = instances.concat(newInstances)
                  setInstances(allInstances)
                }
              }
              element.click()
            }}
          >
            Add Files
          </button>
          {saveButton()}
        </div>
      </form>
    )
  }

interface MeetingUploadModel {
  uploading: WholeVideoUploading
  name: string
  willTranscribe: boolean
  isPublic: boolean
}

type MeetingUploadInstanceComponentProps = {
  uploading: WholeVideoUploading
  name: string
  setName: (x: string) => void
  willTranscribe: boolean
  setTranscribe: (x: boolean) => void
  isPublic: boolean
  setPublic: (x: boolean) => void
}
const MeetingUploadInstanceComponent: React.FC<MeetingUploadInstanceComponentProps> =
  props => {
    const progressVal = useRx(() => props.uploading.progress, 0)
    const progressPercent = `${Math.round(progressVal * 100).toString()}%`
    return (
      <tr>
        <td>
          <input
            className='w-100'
            type='text'
            value={props.name}
            onChange={(ev): void => props.setName(ev.currentTarget.value)}
          />
        </td>
        <td>
          <progress className='w-100' value={progressVal}>
            <div className='progress-bar'>
              <span style={{ width: progressPercent }}>
                Progress: {progressPercent}
              </span>
            </div>
          </progress>
        </td>
        <td>
          <input
            className='w-100'
            type='checkbox'
            id='shouldTranscribe'
            checked={props.willTranscribe}
            onChange={(ev): void =>
              props.setTranscribe(ev.currentTarget.checked)
            }
          />
        </td>
        <td>
          <input
            className='w-100'
            type='checkbox'
            id='shouldBePublic'
            checked={props.isPublic}
            onChange={(ev): void => props.setPublic(ev.currentTarget.checked)}
          />
        </td>
      </tr>
    )
  }

export function meetingUpload(): void {
  console.log('Setting up...')
  authenticateAws()
    .then(() => console.log('Authenticated with AWS'))
    .catch(e => console.error(e))
  render(<MeetingUploadComponent />, document.getElementById('app'))
}
