import React from 'react'
import { OngoingRecording } from '../../utils/recording'

const MeetingStartRecordComponent: React.FC<{
  stream: MediaStream
  onStart: (rec: OngoingRecording) => void
}> = props => {
  return (
    <button
      className='btn btn-primary align-self-stretch'
      onClick={(): void => {
        props.onStart(new OngoingRecording(props.stream!))
      }}
    >
      Start Recording
    </button>
  )
}

export default MeetingStartRecordComponent
