import React, {useState} from 'react'
import {VideoUploading} from "../../utils/aws-upload";

const MeetingFinishedComponent: React.FC<{
  id: string
  upload: VideoUploading
}> = props => {
  const [objectUrl] = useState(() => {
    return URL.createObjectURL(new Blob(props.upload.blobs, { type: "video/webm" }))
  })
  return (
    <div className='flexVertical align-self-stretch'>
      <a href={objectUrl} download='video.webm'>Download Meeting</a>
    </div>
  )
}

export default MeetingFinishedComponent
