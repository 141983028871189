import { AddStreamOptions, VideoStreamMerger } from 'video-stream-merger'
import { VideoTileState } from 'amazon-chime-sdk-js'

type PositionInfo = { x: number; y: number; width: number; height: number }

function equalPositionInfo(left: PositionInfo, right: PositionInfo): boolean {
  return (
    left.x === right.x &&
    left.y === right.y &&
    left.width === right.width &&
    left.height === right.height
  )
}

function equalStreamPositionPair(
  left: [MediaStream, PositionInfo],
  right: [MediaStream, PositionInfo]
): boolean {
  return left[0] === right[0] && equalPositionInfo(left[1], right[1])
}

export class StreamMerger {
  lower: VideoStreamMerger = new VideoStreamMerger({
    width: 1280,
    height: 720,
    fps: 30,
    clearRect: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    audioContext: null,
  })
  audio: Array<MediaStream> = []
  video: Array<[MediaStream, PositionInfo]> = []
  activeSpeaker: string | null = null

  constructor() {
    this.lower.start()
    this.lower.addStream('debugEffect', {
      draw: (
        context: CanvasRenderingContext2D,
        frame: CanvasImageSource,
        done: () => void
      ): void => {
        context.fillStyle = 'black'
        context.fillRect(0, 0, context.canvas.width, context.canvas.height)
        done()
      },
    } as AddStreamOptions)
  }

  get result(): MediaStream | null {
    return this.lower.result
  }

  updateStreams(
    audio: Array<MediaStream>,
    video: Array<VideoTileState>,
    activeSpeakers: Array<string>
  ): void {
    if (activeSpeakers.length > 0) {
      if (activeSpeakers.indexOf(this.activeSpeaker ?? 'x') === -1) {
        this.activeSpeaker = activeSpeakers[0]
      }
    }
    if (this.activeSpeaker === null && video.length > 0) {
      this.activeSpeaker = video[0].boundAttendeeId
    }
    const readyVideo = video.filter(
      x => x.boundVideoElement !== null && x.boundVideoStream !== null
    )
    const contentTiles = readyVideo.filter(x => x.isContent)
    const activeSpeakerTile = readyVideo.find(
      x => x.boundAttendeeId === this.activeSpeaker
    )

    const readyVideoWithPosition: Array<[MediaStream, PositionInfo]> = []
    if (contentTiles.length > 0) {
      readyVideoWithPosition.push([
        contentTiles[0].boundVideoStream!,
        {
          x: 0,
          y: 0,
          width: this.lower.width,
          height: this.lower.height,
        },
      ])
      if (activeSpeakerTile) {
        readyVideoWithPosition.push([
          activeSpeakerTile.boundVideoStream!,
          {
            x: (this.lower.width * 5) / 6,
            y: (this.lower.height * 5) / 6,
            width: this.lower.width / 6,
            height: this.lower.height / 6,
          },
        ])
      }
    } else {
      if (activeSpeakerTile) {
        readyVideoWithPosition.push([
          activeSpeakerTile.boundVideoStream!,
          {
            x: 0,
            y: 0,
            width: this.lower.width,
            height: this.lower.height,
          },
        ])
      }
    }

    const addedV: Array<[MediaStream, PositionInfo]> = []
    const removedV: Array<[MediaStream, PositionInfo]> = []
    for (const current of readyVideoWithPosition) {
      if (this.video.findIndex(x => equalStreamPositionPair(current, x)))
        addedV.push(current)
    }
    for (const old of this.video) {
      if (
        readyVideoWithPosition.findIndex(x => equalStreamPositionPair(old, x))
      )
        removedV.push(old)
    }
    for (const item of removedV) this.lower.removeStream(item[0])
    for (const item of addedV)
      this.lower.addStream(item[0], {
        ...item[1],
        draw: (
          context: CanvasRenderingContext2D,
          frame: CanvasImageSource,
          done: () => void
        ): void => {
          context.drawImage(
            frame,
            item[1].x,
            item[1].y,
            item[1].width,
            item[1].height
          )
          done()
        },
        mute: true,
      } as AddStreamOptions)
    this.video = readyVideoWithPosition

    const added: Array<MediaStream> = []
    const removed: Array<MediaStream> = []
    for (const current of audio) {
      if (this.audio.indexOf(current) === -1) added.push(current)
    }
    for (const old of this.audio) {
      if (audio.indexOf(old) === -1) removed.push(old)
    }
    for (const item of removed) this.lower.removeStream(item)
    for (const item of added) this.lower.addStream(item, undefined)
    this.audio = audio
  }
}
