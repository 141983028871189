import { Room } from '../../models/models'
import { ReconnectingWebSocket } from '../../models/sync'

export function makeRoomSocketHost(room: Room): ReconnectingWebSocket {
  console.log(window.location.protocol)
  const url = `ws${window.location.protocol === 'https:' ? 's' : ''}://${
    window.location.host
  }/rooms/${room.slug}/?name=Host`
  return new ReconnectingWebSocket(url)
}

export function makeRoomSocketName(
  room: Room,
  name: string
): ReconnectingWebSocket {
  const url = `ws${window.location.protocol === 'https:' ? 's' : ''}://${
    window.location.host
  }/rooms/${room.slug}/?name=${encodeURIComponent(name)}`
  return new ReconnectingWebSocket(url)
}

export function makeRoomSocketToken(
  room: Room,
  token: string
): ReconnectingWebSocket {
  const url = `ws${window.location.protocol === 'https:' ? 's' : ''}://${
    window.location.host
  }/rooms/${room.slug}/?token=${token}`
  return new ReconnectingWebSocket(url)
}
