import React from 'react'
import { Observable } from 'rxjs'
import { useRx } from '../../utils/react-rxjs'
import {VideoUploading} from "../../utils/aws-upload";
import {switchMap} from "rxjs/operators";

const MeetingFinishingComponent: React.FC<{
  upload: VideoUploading
}> = props => {
  const partCompletion = useRx(() => props.upload.partCompletion.pipe(switchMap(x => x)), 0, [
    props.upload,
  ])
  const partsWaiting = useRx(() => props.upload.awaiting, 0, [props.upload])
  return (
    <div className='flexVertical align-self-stretch'>
      <progress className='align-self-stretch' value={partCompletion} />
      {
        partsWaiting > 1 ? <span>+{partsWaiting-1}</span> : null
      }
      <p>Finishing upload...</p>
    </div>
  )
}

export default MeetingFinishingComponent
