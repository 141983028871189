import {
  MeetingTextEntry,
  MeetingTextEntrySet,
  TranscriptionResult,
} from '../models/models'

export function combineTranscript(
  base: Array<MeetingTextEntry>,
  transcription?: TranscriptionResult
): Array<MeetingTextEntry> {
  if (transcription === undefined) return base
  const out: Array<MeetingTextEntry> = []
  const remainingBaseEntries = [...base]
  let current: MeetingTextEntry | null = null
  let lastTime = 0
  let negId = -1

  function getTime(value?: string): number {
    if (value) {
      return parseFloat(value)
    } else {
      return lastTime
    }
  }

  for (const word of transcription.results.items) {
    while (
      remainingBaseEntries.length > 0 &&
      getTime(word.start_time) > remainingBaseEntries[0].time_start
    ) {
      if (current !== null) {
        out.push(current)
        current = null
      }
      out.push(remainingBaseEntries.shift()!)
    }
    const t = getTime(word.start_time)
    const newSource = getSpeaker(transcription, t) ?? -999
    if (current !== null) {
      if (newSource !== current.participant) {
        out.push(current)
        current = null
      }
    }
    if (current === null) {
      current = {
        id: negId--,
        meeting: -1,
        time_start: t,
        time_end: getTime(word.end_time),
        participant: newSource,
        content: word.alternatives[0].content,
      }
    } else {
      if (word.type === 'pronunciation') {
        current.content += ' '
      }
      current.content += word.alternatives[0].content
      current.time_end = getTime(word.end_time)
      if (word.type === 'punctuation' && word.alternatives[0].content == '.') {
        out.push(current)
        current = null
      }
    }
    lastTime = getTime(word.end_time)
  }
  if (current !== null) {
    out.push(current)
    current = null
  }
  out.push(...remainingBaseEntries)

  return out
}

export function organizeBySpeaker(
  entries: Array<MeetingTextEntry>
): Array<MeetingTextEntrySet> {
  const sections: Array<MeetingTextEntrySet> = []
  for (const note of entries) {
    appendTextEntry(note, sections)
  }
  return sections
}

export function appendTextEntry(
  newEntry: MeetingTextEntry,
  to: Array<MeetingTextEntrySet>
): void {
  if (to.length == 0) {
    to.push({
      participant: newEntry.participant,
      entries: [newEntry],
    })
  } else {
    const currentSection = to[to.length - 1]
    if (
      newEntry.participant !== currentSection.participant &&
      newEntry.participant !== undefined
    ) {
      if (currentSection.entries.length > 0) {
        to.push({
          participant: newEntry.participant,
          entries: [newEntry],
        })
      }
    } else {
      currentSection.entries.push(newEntry)
    }
  }
}

export function getSpeaker(
  result: TranscriptionResult,
  time: number
): number | undefined {
  if (
    result.results &&
    result.results.speaker_labels &&
    result.results.speaker_labels.segments
  ) {
    for (const item of result.results.speaker_labels.segments) {
      if (
        time >= parseFloat(item.start_time) &&
        time <= parseFloat(item.end_time)
      ) {
        try {
          return -1 - parseInt(item.speaker_label.substring(4))
        } catch (e) {
          return -999
        }
      }
    }
  }
  return undefined
}

export function insertOrdered(
  list: Array<MeetingTextEntry>,
  newEntry: MeetingTextEntry
): void {
  const destIndex = list.findIndex(p => p.time_start >= newEntry.time_start)
  list.splice(destIndex, 0, newEntry)
}
