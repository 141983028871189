import React, { useState } from 'react'
import { BehaviorSubject, combineLatest, Observable, zip } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'
import {
  Meeting,
  MeetingTextEntry,
  Participant,
  SummarizePrompt,
  TranscriptionResult,
} from '../../models/models'
import { useRefSetup } from '../../utils/react-extensions'
import { useStateSubject } from '../../utils/react-rxjs'
import {
  combineTranscript,
  organizeBySpeaker,
} from '../../utils/transcript-combiner'
import MeetingText from './MeetingText'
import downloadSvg from '../../../assets/save.svg'
import MeetingSummary from './MeetingSummary'

export type MeetingNotesComponentProps = {
  name?: string
  meetingId: number
  transcript?: TranscriptionResult
  currentTime: Observable<number>
  getCurrentTime: () => number
  onTimeClick?: (seconds: number) => void
}
const MeetingNotesComponent: React.FC<MeetingNotesComponentProps> = props => {
  const showNotes = useStateSubject(() => true)
  const showSummary = useStateSubject(() => true)
  const showTranscript = useStateSubject(() => true)
  const [hasNotes, setHasNotes] = useState(false)

  const entries = useRefSetup(() => {
    const subject = new BehaviorSubject<Array<MeetingTextEntry>>([])
    fetch(`/api/meeting-text-entries/?meeting=${props.meetingId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    })
      .then(x => x.json())
      .then((entries: Array<MeetingTextEntry>) => {
        const results = entries.sort((a, b) => a.time_start - b.time_start)
        subject.next(results)
        if (results.length > 0) {
          setHasNotes(true)
        }
      })
    return subject
  })
  const participants = useRefSetup(() => {
    const subject = new BehaviorSubject<Record<string, Participant>>({})
    fetch(`/api/participants/?meeting=${props.meetingId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    })
      .then(x => x.json())
      .then((participants: Array<Participant>) => {
        const participantsRaw: Record<string, Participant> = {}
        for (const p of participants) {
          participantsRaw[p.id] = p
        }
        subject.next(participantsRaw)
      })
    return subject
  })

  function submitNote(message: string): Promise<void> {
    const time = props.getCurrentTime()
    return fetch(`/api/meeting-text-entries/`, {
      method: 'POST',
      body: JSON.stringify({
        content: message,
        meeting: props.meetingId,
        time_start: time,
        time_end: time,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': document.getElementById('csrf')!.textContent!,
      },
      credentials: 'same-origin',
    })
      .then(x => x.json())
      .then((x: MeetingTextEntry) => {
        const destIndex = entries.value.findIndex(
          p => p.time_start >= x.time_start
        )
        const newEntries = entries.value.concat([])
        newEntries.splice(destIndex, 0, x)
        entries.next(newEntries)
      })
  }

  function removeNote(note: MeetingTextEntry): Promise<void> {
    return fetch(`/api/meeting-text-entries/${note.id}/`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': document.getElementById('csrf')!.textContent!,
      },
      credentials: 'same-origin',
    }).then(() => {
      const newEntries = entries.value.concat([])
      newEntries.slice(entries.value.indexOf(note))
      entries.next(newEntries)
    })
  }

  const handleDownload = (): void => {
    zip(combinedNotes.pipe(take(1)), participants.pipe(take(1)))
      .pipe()
      .subscribe({
        next([bySpeaker, speakerNames]) {
          let result = ''
          for (const speakerEntry of bySpeaker) {
            const speakerName = speakerEntry.participant
              ? speakerNames[speakerEntry.participant] ??
                `Speaker ${-speakerEntry.participant}`
              : 'Note'
            result += speakerName
            result += '\n'
            for (const entry of speakerEntry.entries) {
              const secondsRounded = Math.round(entry.time_start)
              const minNum = Math.floor(secondsRounded / 60)
              const secNum = secondsRounded % 60
              let sec = secNum.toString()
              if (sec.length < 2) {
                sec = '0' + sec
              }
              const timeText = `${minNum}:${sec}`

              result += '  '
              result += timeText
              result += '  '
              if (entry.participant === undefined) {
                result += '(Note: ' + entry.content + ')'
              } else {
                result += entry.content
              }
              result += '\n'
            }
          }
          const anchorE = document.createElement('a')
          anchorE.href = 'data:text/plain,' + encodeURIComponent(result)
          anchorE.download = props.name ?? 'Transcript'
          document.body.appendChild(anchorE)
          anchorE.click()
          document.body.removeChild(anchorE)
        },
      })
  }

  const combinedNotes = useRefSetup(() =>
    combineLatest([showNotes, showTranscript]).pipe(
      map(([showNotes, showTranscript]) => {
        if (showNotes) {
          if (showTranscript && props.transcript) {
            return entries.pipe(
              map(x =>
                organizeBySpeaker(combineTranscript(x, props.transcript!))
              )
            )
          } else {
            return entries.pipe(map(organizeBySpeaker))
          }
        } else {
          if (showTranscript && props.transcript) {
            return new BehaviorSubject(
              organizeBySpeaker(combineTranscript([], props.transcript!))
            )
          } else {
            return new BehaviorSubject([])
          }
        }
      }),
      switchMap(x => x)
    )
  )

  return (
    <div className='flex-grow-1'>
      {props.transcript && (
        <div className='show-hide-input-container'>
          <div className='checkbox mb-0'>
            <input
              type='checkbox'
              id='showNotes'
              checked={showNotes.value}
              onChange={(ev): void => showNotes.next(ev.currentTarget.checked)}
            />
            <label htmlFor='showNotes'>Show Notes</label>
          </div>
          <div className='checkbox mb-0'>
            <input
              type='checkbox'
              id='showSummary'
              checked={showSummary.value}
              onChange={(ev): void => showSummary.next(ev.currentTarget.checked)}
            />
            <label htmlFor='showSummary'>Show Summary</label>
          </div>
          <div className='checkbox mb-0'>
            <input
              type='checkbox'
              id='showTranscript'
              checked={showTranscript.value}
              onChange={(ev): void =>
                showTranscript.next(ev.currentTarget.checked)
              }
            />
            <label htmlFor='showTranscript'>Show Transcript</label>
          </div>
          <button
            className='btn small background'
            type='button'
            onClick={handleDownload}
          >
            <img src={downloadSvg} alt='Download Transcript' />
          </button>
        </div>
      )}
      {showSummary.value && (hasNotes || props.transcript) &&(
      <MeetingSummary
        entries={combinedNotes}
        meetingId={props.meetingId}
      />)}

      <MeetingText
        {...props}
        speakerNames={participants}
        entries={combinedNotes}
        submit={submitNote}
        onRemove={removeNote}
        style={{ maxHeight: '50vh' }}
      />
    </div>
  )
}

export default MeetingNotesComponent
