import React from 'react'
import { from, Observable, Subject } from 'rxjs'
import {
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  share,
} from 'rxjs/operators'
import { authenticateAws } from '../../utils/aws-upload'
import { notNull } from '../../utils/rxjs-extensions'
import { Meeting, MeetingRaw } from '../../models/models'
import { render } from 'react-dom'
import MeetingCreateComponent from './MeetingCreateComponent'

export const autoStart = true

function upsertMeeting(meeting: Partial<Meeting>): Promise<Meeting> {
  console.log('Sending update...')
  return fetch(
    meeting.id
      ? `/api/meetings/${meeting.id}/?text=true`
      : '/api/meetings/?text=true',
    {
      method: meeting.id ? 'PATCH' : 'POST',
      body: JSON.stringify(Meeting.toJsonPartial(meeting)),
      credentials: 'same-origin',
      headers: {
        'X-CSRFToken': document.getElementById('csrf')!.textContent!,
        'Content-Type': 'application/json',
      },
    }
  )
    .then(result => {
      if (!result.ok) {
        throw result.statusText
      }
      return result.json()
    })
    .then(result => {
      return Meeting.parse(result as MeetingRaw)
    })
}

export function addMeetingToCollection(meetingId:string,collectionId:string): Promise<Response> {
  return fetch('/collection/add-to-meeting-to-collection/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken':
      document.getElementById('csrf')!.textContent!,
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      meetingId: meetingId,
      collectionId: collectionId,
    }),
  })

}

function sendMeetingUpdates(
  observable: Observable<Partial<Meeting>>,
  startId: number | undefined = undefined
): Observable<Meeting | undefined> {
  let id: number | undefined = startId
  return observable.pipe(
    distinctUntilChanged(Meeting.equal),
    debounceTime(1000),
    mergeMap(meeting => {
      // console.log("Would have uploaded ", { ...meeting, guid })
      // guid = "UUID HERE"
      // return of(meeting as Meeting)
      return from(
        upsertMeeting({ ...meeting, id })
          .then(x => {
            id = x.id
            return x
          })
          .catch(err => {
            alert('Error: ' + err)
            return undefined
          })
      )
    })
  )
}

export default function meetingCreate(model?: Meeting): void {
  console.log('Setting up V8...')
  authenticateAws()
    .then((): void => console.log('Authenticated with AWS'))
    .catch(err => {
      console.error(err)
      alert(
        'An error occurred in preparing to upload.  Please refresh the page and try again.'
      )
    })
  const subj = new Subject<Partial<Meeting>>()
  if (model !== undefined) {
    subj.next(model)
  }
  const meetingUpdates = sendMeetingUpdates(subj, model?.id).pipe(share())
  meetingUpdates.subscribe(
    x => {
      if (x !== undefined && x.recording) {
        console.log(`Got `, x)
        window.onbeforeunload = null
        // if collection in url, add to collection
        const collectionId = window.location.href.includes('collection') ? window.location.href.split('collection=')[1] : null
        if (collectionId !== null && x.guid !== undefined) {
          addMeetingToCollection(x.guid,collectionId).then((response) => {
            window.location.href = '/meetings/meeting/' + x.guid
          })
          .catch(err => {
            console.error(err)
            window.location.href = '/meetings/meeting/' + x.guid
          })
        } else {
        window.location.href = '/meetings/meeting/' + x.guid
        }
      }
    },
    err => {
      console.error(err)
      alert(
        'An error occurred while uploading.  Please refresh the page and try again.'
      )
    }
  )
  render(
    <MeetingCreateComponent
      continueMeeting={model}
      meetingUpdates={meetingUpdates.pipe(notNull())}
      setMeeting={(x): void => subj.next(x)}
    />,
    document.getElementById('app')
  )
}
