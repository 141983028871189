/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable @typescript-eslint/no-explicit-any */
;(window as any).global = window

import '../sass/main.scss'
import { meetingDetail } from './containers/detail'
import meetingCreate from './containers/create'
import { meetingUpload } from './containers/upload'
import roomDetail from './containers/meeting'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

if (PUBLIC_DSN) {
  Sentry.init({
    dsn: PUBLIC_DSN,
    autoSessionTracking: false,
    integrations: [new BrowserTracing()],
    environment: ENVIRONMENT,
    release: GIT_RELEASE,
  })
}

;(window as any).app_meetingCreate = meetingCreate
;(window as any).app_meetingDetail = meetingDetail
;(window as any).app_meetingUpload = meetingUpload
;(window as any).app_roomDetail = roomDetail
