import React, { useEffect } from 'react'
import { listenEndOnAnyTrack } from '../../utils/recording'

import { MicrophoneActivityMeter, SoundTestComponent } from '../microphone'
import { ReactProperty } from '../../utils/react-extensions'

export type RecordInputComponentProps = {
  className?: string
  enabled?: boolean
  includeSoundTest?: boolean
  image: string
  completedImage: string
  name: string
  request: () => Promise<MediaStream>
  streamProperty: ReactProperty<MediaStream | null>
}
const RecordInputComponent: React.FC<RecordInputComponentProps> = props => {
  const { value: stream, set: setStream } = props.streamProperty
  const enabled = props.enabled === undefined ? true : props.enabled

  useEffect(() => {
    if (stream !== null) {
      return listenEndOnAnyTrack(stream.getTracks(), (): void => {
        setStream(null)
      })
    } else {
      return (): void => {
        /* noop */
      }
    }
  }, [stream])

  const hasVideo = stream && stream.getVideoTracks().length > 0
  const hasAudio = stream && stream.getAudioTracks().length > 0
  const indicatorComponent = (
    <div className='flexVertical' style={{ width: '48px' }}>
      {stream && hasVideo && (
        <video
          muted={true}
          autoPlay={true}
          className='m-0'
          ref={(x): MediaStream | null => x && (x.srcObject = stream)}
          width={48}
          height={27}
        />
      )}
      {stream && hasAudio && props.includeSoundTest && (
        <SoundTestComponent className='m-0' />
      )}
      {stream && hasAudio && (
        <MicrophoneActivityMeter
          className='m-0'
          style={{ width: '48px', height: '16px' }}
          stream={stream}
        />
      )}
    </div>
  )
  const completed = stream !== null

  return (
    <div className={`flexHorizontal p-3 ${props.className}`}>
      <button
        type='button'
        className={`btn btn-primary align-items-center d-flex gap-4 justify-content-start text-start flex-grow-1 ${
          completed ? 'btn-outline' : ''
        }`}
        disabled={!enabled && stream === null}
        onClick={(): void => {
          props.request().then(x => {
            setStream(x)
          })
        }}
      >
        <img src={completed ? props.completedImage : props.image} />
        <div>{completed ? `✓ ${props.name}` : `Setup ${props.name}`}</div>
      </button>
      {indicatorComponent}
    </div>
  )
}
export default RecordInputComponent
