import { ThemeProvider } from 'styled-components'
import React, { useEffect } from 'react'
import {
  ControlBar,
  MeetingProvider,
  lightTheme,
  useMeetingManager,
  VideoTileGrid,
  AudioInputControl,
  AudioOutputControl,
  ContentShareControl,
  VideoInputControl,
} from 'amazon-chime-sdk-component-library-react'
import { ChimeJoinInfo } from '../../models/models'
import { Styleable } from '../../utils/react-extensions'
import VideoRecorder from './VideoRecorder'

interface VideoCallProps extends Styleable {
  chimeDetails: ChimeJoinInfo
  record: boolean
  onStream: (m: MediaStream) => void
}

const VideoCall: React.FC<VideoCallProps> = props => {
  const style = Object.assign({}, lightTheme)
  style.fontSizes.baseFontSize = '24px'
  style.iconButtonSizes.lg = '32px'
  style.iconButtonSizes.md = '32px'
  style.iconButtonSizes.sm = '32px'
  return (
    <ThemeProvider theme={style}>
      <MeetingProvider>
        <VideoCallInner {...props} />
      </MeetingProvider>
    </ThemeProvider>
  )
}
const VideoCallInner: React.FC<VideoCallProps> = props => {
  const meetingManager = useMeetingManager()
  const { chimeDetails, record, onStream, ...divProps } = props
  // const { toggleVideo } = useLocalVideo()
  const startJoinMeetingAsync = async (): Promise<void> => {
    await meetingManager.join(props.chimeDetails)
    await meetingManager.start()
  }

  useEffect(() => {
    startJoinMeetingAsync()
  }, [chimeDetails])
  return (
    <div {...divProps}>
      <VideoTileGrid className='flexGrow align-self-stretch' />
      <ControlBar
        className='video-call-control-bar'
        showLabels
        layout='bottom'
        responsive
      >
        <AudioInputControl />
        <AudioOutputControl />
        <ContentShareControl />
        <VideoInputControl />
      </ControlBar>
      {record && <VideoRecorder onStream={onStream} />}
    </div>
  )
}

export default VideoCall
