import React from 'react'
import { Room } from '../../models/models'
import { render } from 'react-dom'
import { useStateFunction } from '../../utils/react-extensions'
import { authenticateAws } from '../../utils/aws-upload'
import { startingChimeCallA, UnauthorizedStatus } from './ConnectRoomsocket'
import NameSelect from './NameSelect'
import {
  makeRoomSocketHost,
  makeRoomSocketToken,
  makeRoomSocketName,
} from './services'

function getParticipantToken(room: Room): string | null {
  const time = parseInt(
    window.localStorage.getItem('participant_token_time') ?? '0'
  )
  const storedRoom = window.localStorage.getItem('participant_token_room')
  if (
    storedRoom === room.id.toString() &&
    Date.now() - time < 1000 * 60 * 60 * 24
  ) {
    return window.localStorage.getItem('participant_token')
  } else {
    return null
  }
}

export default function roomDetail(): void {
  console.log('Setting up...')
  const isOwner: boolean = JSON.parse(
    document.getElementById('isOwner')!.textContent!
  )
  if (isOwner) {
    authenticateAws()
      .then((): void => console.log('Authenticated with AWS'))
      .catch(err => {
        console.error(err)
        alert(
          'An error occurred in preparing to upload.  Please refresh the page and try again.'
        )
      })
    window.onbeforeunload = function (ev: BeforeUnloadEvent): string {
      ev.preventDefault()
      return "Are you sure you want to leave this page?  Your recording will be lost if you do.  Click 'End Meeting' if you're done with the meeting."
    }
  }
  const room: Room = JSON.parse(document.getElementById('room')!.textContent!)
  const MainComponent: React.FC = () => {
    const [Current, setCurrent] = useStateFunction<React.FC | null>(() => null)
    if (Current === null) {
      if (isOwner) {
        setCurrent(
          startingChimeCallA(room, makeRoomSocketHost(room), setCurrent)
        )
      } else {
        const token = getParticipantToken(room)
        if (token) {
          setCurrent(
            UnauthorizedStatus(
              room,
              makeRoomSocketToken(room, token),
              setCurrent
            )
          )
        } else {
          setCurrent(
            NameSelect(name => {
              setCurrent(
                UnauthorizedStatus(
                  room,
                  makeRoomSocketName(room, name),
                  setCurrent
                )
              )
            })
          )
        }
      }
      return <div />
    } else {
      return <Current />
    }
  }
  render(<MainComponent />, document.getElementById('app'))
}
