import React, { useState } from 'react'
import { NEVER, Observable } from 'rxjs'
import { useRx } from '../../utils/react-rxjs'
import {
  MeetingTextEntry,
  MeetingTextEntrySet,
  Participant,
} from '../../models/models'
import { Styleable, useRefSetup } from '../../utils/react-extensions'
import TranscriptSection from './TranscriptSection'

export interface MeetingTextProps extends Styleable {
  entries: Observable<Array<MeetingTextEntrySet>>
  speakerNames: Observable<Record<string, Participant>>
  submit: (text: string) => void
  onTimeClick?: (seconds: number) => void
  currentTime?: Observable<number>
  onRemove?: (entry: MeetingTextEntry) => void
}

const MeetingText: React.FC<MeetingTextProps> = props => {
  const [upcomingNote, setUpcomingNote] = useState('')
  const entries = useRx(() => props.entries, [], [props.entries])
  const speakerNames = useRx(() => props.speakerNames, {})
  const previousIds = useRefSetup<Set<number>>(() => new Set())
  let newId: number | undefined = undefined
  for (const section of entries) {
    for (const entry of section.entries) {
      if (!previousIds.has(entry.id)) {
        previousIds.add(entry.id)
        newId = entry.id
      }
    }
  }
  if (newId !== undefined) {
    window.setTimeout(() => {
      const ele = document.getElementById(`transcript-note-${newId}`)
      ele?.scrollIntoView()
    }, 10)
  }

  function submitNote(): void {
    props.submit(upcomingNote)
    setUpcomingNote('')
  }

  return (
    <div className={`meeting-text d-flex flex-column p-3 ${
      props.className || ''
    }`}
         style={props.style}>
      <h2>Transcript</h2>
      <div
        className='d-flex flex-column flex-grow-1 scrolling'
      >
        {entries.map(x => (
          <TranscriptSection
            key={x.entries[0].id}
            label={
              x.participant
                ? speakerNames[x.participant]?.name ??
                  `Speaker ${-x.participant}`
                : 'Note'
            }
            entries={x.entries}
            currentTime={props.currentTime ?? NEVER}
            onTimeClick={props.onTimeClick}
            onRemove={props.onRemove}
          />
        ))}
      </div>
      <div className='d-flex mt-3'>
        <input
          className='flex-grow-1 me-3'
          style={{minWidth: "0px"}}
          type='text'
          value={upcomingNote}
          onChange={(x): void => setUpcomingNote(x.target.value)}
          onKeyPress={(ev): boolean => {
            const keyCode = ev.keyCode || ev.which
            if (keyCode === 13) {
              ev.preventDefault()
              submitNote()
              return false
            }
            return true
          }}
        />
        <button className='btn btn-primary' onClick={submitNote}>
          Add
        </button>
      </div>
    </div>
  )
}

export default MeetingText
