import { CSSProperties, useRef, useState } from 'react'

export interface ReactProperty<T> {
  value: T
  set(value: T): void
}

export function useStateProperty<T>(startingValue: T): ReactProperty<T> {
  const [value, set] = useState(startingValue)
  return { value, set }
}

export function useStatePropertyLazy<T>(
  startingValue: () => T
): ReactProperty<T> {
  const [value, set] = useState(startingValue)
  return { value, set }
}

export interface Styleable {
  className?: string
  style?: CSSProperties
}

export function useStateFunction<T>(
  initialValue: () => T
): [T, (t: T) => void] {
  const [direct, setDirect] = useState<T>(initialValue)
  return [direct, (x): void => setDirect(() => x)]
}

export function useRefSetup<T>(setup: () => T): T {
  const ref = useRef<T>()
  if (ref.current === undefined) {
    ref.current = setup()
  }
  return ref.current
}
