import React, { ReactElement, useState } from 'react'

const NameSelect = (onResult: (name: string) => void) =>
  function NameSelectFC(): ReactElement {
    const [name, setName] = useState<string>('')
    return (
      <form
        className='h-100 flexVertical'
        onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
          e.preventDefault()
          onResult(name)
        }}
      >
        <label className='m-3'>Enter your name:</label>
        <input
          className='m-3'
          onChange={(ev): void => setName(ev.target.value)}
          value={name}
        />
        <button className='btn btn-primary btn-lg m-3' type='submit'>
          Submit
        </button>
      </form>
    )
  }

export default NameSelect
