import React, { useEffect } from 'react'
import { combineLatest, Observable, of } from 'rxjs'
import { useRxSubscription } from '../../utils/react-rxjs'
import {
  CompositeMediaStream,
  listenEndOnAnyTrack,
  OngoingRecording,
} from '../../utils/recording'
import { startWith, switchMap } from 'rxjs/operators'
import {
  s3StreamedUpload,
  s3StreamedUploadFromExisting,
  VideoUploading,
} from '../../utils/aws-upload'
import { ListingStatus, Meeting } from '../../models/models'

import { useStateProperty } from '../../utils/react-extensions'
import { autoStart } from './'
import MeetingRecordingComponent from './MeetingRecordingComponent'
import RecordSetupComponent from './RecordSetupComponent'

interface OngoingRecordingAndStream {
  recording?: OngoingRecording
  stream?: VideoUploading
}

const MeetingCreateComponent: React.FC<{
  continueMeeting?: Meeting
  meetingUpdates: Observable<Meeting>
  setMeeting: (value: Partial<Meeting>) => void
}> = props => {
  const name = useStateProperty(props.continueMeeting?.name ?? '')
  const recordStart = useStateProperty<Date | undefined>(
    props.continueMeeting?.start_datetime
  )
  const recordEnd = useStateProperty<Date | undefined>(
    props.continueMeeting?.end_datetime
  )
  const willTranscribe = useStateProperty(
    props.continueMeeting?.will_transcribe ?? false
  )
  const isPublic = useStateProperty(
    props.continueMeeting?.listing_status === ListingStatus.UNLISTED
  )

  const stream = useStateProperty<CompositeMediaStream | null>(null)
  const ongoing = useStateProperty<OngoingRecordingAndStream>({})

  const ongoingUpload = ongoing.value.stream
  if (ongoingUpload) {
    window.onbeforeunload = function (ev: BeforeUnloadEvent): string {
      ev.preventDefault()
      return 'Are you sure you want to leave this page?  Your recording will be lost if you do.'
    }
  } else {
    window.onbeforeunload = null
  }

  useRxSubscription(() => {
    if (!ongoingUpload) return of(0).subscribe()
    return combineLatest([
      ongoingUpload.fullUrl.pipe(startWith(undefined)),
      ongoingUpload.info.pipe(startWith(undefined)),
    ]).subscribe(
      x => {
        const completeS3Id = x[0]
        const uploadInfo = x[1]
        if (uploadInfo) {
          console.log('Setting the meeting')
          props.setMeeting({
            name: name.value,
            multipart_upload_id: uploadInfo.UploadId,
            multipart_upload_key: uploadInfo.Key,
            start_datetime: recordStart.value,
            end_datetime: recordEnd.value,
            will_transcribe: willTranscribe.value,
            listing_status: isPublic
              ? ListingStatus.UNLISTED
              : ListingStatus.PRIVATE,
            recording_s3: completeS3Id,
            summaries:[]
          })
        }
      },
      error => {
        console.error(error)
        window.onbeforeunload = null
        alert(
          'Something went wrong with streaming.  Please start recording again.'
        )
      }
    )
  })

  function startRecording(recording: OngoingRecording): void {
    recordStart.set(new Date())
    const stream =
      props.continueMeeting &&
      props.continueMeeting.multipart_upload_key &&
      props.continueMeeting.multipart_upload_id
        ? s3StreamedUploadFromExisting(
            props.continueMeeting.multipart_upload_key,
            props.continueMeeting.multipart_upload_id,
            recording.data
          )
        : s3StreamedUpload(
            recording.data,
            recording.mimeType,
            recording.extension
          )
    recording.resume()
    ongoing.set({
      recording,
      stream,
    })
  }

  function finishRecording(): void {
    recordEnd.set(new Date())
    const currentStream = ongoing.value?.stream
    if (currentStream === null)
      throw new Error(
        'currentStream is empty, yet an attempt to finish the recording was made.'
      )
    ongoing.set({
      recording: undefined,
      stream: ongoing.value?.stream,
    })
  }

  useEffect(() => {
    const currentStream = stream.value
    const rec = ongoing.value?.recording ?? null
    if (currentStream) {
      if (rec !== null) {
        if (rec.stream !== currentStream.stream) {
          rec.changeSources(currentStream.stream)
        }
      }
      return listenEndOnAnyTrack(currentStream.sourceTracks, (): void => {
        if (rec !== null) {
          if (!rec.paused) {
            console.log('Pausing')
            rec.pause()
          }
        }
        currentStream.context.close()
        for (const source of currentStream.sourceTracks) {
          source.stop()
        }
        stream.set(null)
      })
    } else {
      return (): void => {
        /* noop */
      }
    }
  }, [stream.value, ongoing.value])

  if (stream.value !== null) {
    return (
      <MeetingRecordingComponent
        stream={stream.value!.stream}
        recording={ongoing.value?.recording ?? null}
        startRecording={startRecording}
        finishRecording={finishRecording}
        name={name}
        upload={ongoing.value.stream ?? null}
        willTranscribe={willTranscribe}
        isPublic={isPublic}
        meetingUpdates={props.meetingUpdates}
      />
    )
  } else {
    return (
      <RecordSetupComponent
        continuing={props.continueMeeting}
        onReady={
          autoStart
            ? (x): void => {
                stream.set(x)
                startRecording(new OngoingRecording(x.stream))
              }
            : stream.set
        }
      />
    )
  }
}

export default MeetingCreateComponent
