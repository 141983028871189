import React, { useState } from 'react'
import { formatDate } from '../../utils/formatters'
import { combineTracks, CompositeMediaStream } from '../../utils/recording'

import cameraSvg from '../../../assets/camera.svg'
import cameraSvgLight from '../../../assets/camera-light.svg'
import microphoneSvg from '../../../assets/microphone.svg'
import microphoneSvgLight from '../../../assets/microphone-light.svg'
import screenAudioSvg from '../../../assets/screen_audio.svg'
import screenAudioSvgLight from '../../../assets/screen_audio-light.svg'
import screenSvg from '../../../assets/screen.svg'
import screenSvgLight from '../../../assets/screen-light.svg'
import { ReactProperty } from '../../utils/react-extensions'
import { Meeting } from '../../models/models'
import { autoStart } from './'
import RecordInputComponent from './RecordInputComponent'

export type RecordSetupComponentProps = {
  continuing?: Meeting
  onReady: (stream: CompositeMediaStream) => void
}
const RecordSetupComponent: React.FC<RecordSetupComponentProps> = props => {
  const [streams, setStreams] = useState<
    Record<string, MediaStream | undefined>
  >({})
  const readyEnabled = Object.values(streams).some(
    x => x && x.getAudioTracks().length > 0
  )
  const streamsHasVideo = Object.values(streams).some(
    x => x && x.getVideoTracks().length > 0
  )
  function stream(key: string): ReactProperty<MediaStream | null> {
    return {
      value: streams[key] ?? null,
      set: (x): void => {
        const copy = Object.assign({}, streams)
        copy[key] = x ?? undefined
        setStreams(copy)
      },
    }
  }

  return (
    <div className='matchParent flexVertical'>
      <div className='flexVertical'>
        <div className='align-self-stretch'>
          <label>Enable Access</label>
          {props.continuing !== undefined ? (
            <p>
              Before continuing your recording (
              {props.continuing.name
                ? props.continuing.name
                : formatDate(props.continuing.start_datetime!)}
              ), please set up your screen and microphone again.
            </p>
          ) : (
            <p>
              Before beginning a new recording, please choose what you would
              like to record.
            </p>
          )}
        </div>
        <div className='flexHorizontal align-self-stretch textStyle'>
          <div className='flexVertical flexGrow'>
            <RecordInputComponent
              className='align-self-stretch'
              image={microphoneSvgLight}
              completedImage={microphoneSvg}
              name='Microphone'
              includeSoundTest={true}
              request={(): Promise<MediaStream> =>
                navigator.mediaDevices.getUserMedia({
                  audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                  },
                })
              }
              streamProperty={stream('mic')}
            />
            {navigator.userAgent.indexOf('Firefox') == -1 &&
              navigator.appVersion.toLowerCase().indexOf('windows') !== -1 && (
                <RecordInputComponent
                  className='align-self-stretch'
                  image={screenAudioSvgLight}
                  completedImage={screenAudioSvg}
                  name='Screen With Audio'
                  request={(): Promise<MediaStream> =>
                    navigator.mediaDevices.getDisplayMedia({
                      audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        sampleRate: 44100,
                      },
                      video: {
                        width: { max: 640 },
                        height: { max: 480 },
                        frameRate: { max: 30 },
                      },
                    })
                  }
                  enabled={!streamsHasVideo}
                  streamProperty={stream('direct')}
                />
              )}
            <RecordInputComponent
              className='align-self-stretch'
              image={screenSvgLight}
              completedImage={screenSvg}
              name='Screen'
              request={(): Promise<MediaStream> =>
                navigator.mediaDevices.getDisplayMedia({
                  video: {
                    width: { max: 640 },
                    height: { max: 480 },
                    frameRate: { max: 30 },
                  },
                })
              }
              streamProperty={stream('screen')}
              enabled={!streamsHasVideo}
            />
            <RecordInputComponent
              className='align-self-stretch'
              image={cameraSvgLight}
              completedImage={cameraSvg}
              name='Camera'
              request={(): Promise<MediaStream> =>
                navigator.mediaDevices.getUserMedia({
                  video: {
                    width: { max: 640 },
                    height: { max: 480 },
                    frameRate: { max: 30 },
                  },
                })
              }
              streamProperty={stream('cam')}
              enabled={!streamsHasVideo}
            />
          </div>
          <div className='flexVertical flexGrow align-self-stretch'>
            <button
              disabled={!readyEnabled}
              className='btn btn-primary align-self-stretch'
              type='button'
              onClick={(): void =>
                props.onReady(
                  combineTracks(
                    Object.values(streams).filter(
                      x => x !== undefined
                    ) as Array<MediaStream>
                  )
                )
              }
            >
              {autoStart ? 'Start Recording' : 'Ready to go!'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecordSetupComponent
