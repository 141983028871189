import React, { HTMLProps } from 'react'
import microphoneSvg from '../../assets/microphone_small.svg'
import testSound from '../../assets/soundtest.wav'
import playSvg from '../../assets/play-light.svg'

export type MicrophoneActivityProps = { stream: MediaStream }
export const MicrophoneActivity: React.FC<MicrophoneActivityProps> = props => {
  return (
    <div className='d-flex mb-2 align-items-center'>
      <img src={microphoneSvg} alt='Microphone Icon' />
      <MicrophoneActivityMeter className='flex-grow-1' {...props} />
    </div>
  )
}

export interface MicrophoneActivityMeterProps
  extends HTMLProps<HTMLMeterElement> {
  stream: MediaStream
}
export type MicrophoneActivityMeterState = { micLevel: number }
export class MicrophoneActivityMeter extends React.Component<
  MicrophoneActivityMeterProps,
  MicrophoneActivityMeterState
> {
  max = 0.3
  constructor(props: MicrophoneActivityMeterProps) {
    super(props)
    this.state = { micLevel: 0 }
  }

  listener = (ev: AudioProcessingEvent): void => {
    const levelAverage = Math.sqrt(
      ev.inputBuffer
        .getChannelData(0)
        .reduce((prev, curr) => prev + curr * curr, 0) / ev.inputBuffer.length
    )
    // this.max = Math.max(levelAverage, this.max)
    this.setState({ micLevel: Math.min(1, levelAverage / this.max) })
  }

  node: ScriptProcessorNode
  componentDidMount(): void {
    const audioContext = new AudioContext()
    const mic = audioContext.createMediaStreamSource(this.props.stream)
    const node = audioContext.createScriptProcessor(1024, 1, 1)
    mic.connect(node)
    node.connect(audioContext.destination)
    node.addEventListener('audioprocess', this.listener)
    this.node = node
  }
  componentWillUnmount(): void {
    this.node.removeEventListener('audioprocess', this.listener)
  }

  render(): React.ReactElement {
    return <meter {...this.props} value={this.state.micLevel} />
  }
}

export const SoundTestComponent: React.FC<HTMLProps<HTMLButtonElement>> =
  props => (
    <button
      type='button'
      className={'btn btn-tiny btn-outline' + (props.className || '')}
      onClick={(): void => {
        const audio = new Audio(testSound)
        audio.play()
      }}
    >
      <img src={playSvg} alt='Play' />
    </button>
  )
