import React, { useState } from 'react'
import { render } from 'react-dom'
import { BehaviorSubject } from 'rxjs'
import { ListingStatus, Meeting } from '../models/models'
import { authenticateAws } from '../utils/aws-upload'
import { formatDate } from '../utils/formatters'
import { useRefSetup, useStateProperty } from '../utils/react-extensions'
import meetingCreate from './create'
import MeetingNotesComponent from './MeetingNotesComponent'
import editSvg from '../../assets/edit.svg'

export const MeetingViewComponent: React.FC<{ original: Meeting }> = props => {
  const base = useStateProperty(props.original)
  const willTranscribe = useStateProperty(base.value.will_transcribe)
  const isPublic = useStateProperty(
    base.value.listing_status === ListingStatus.UNLISTED
  )
  const name = useStateProperty(base.value.name)
  const time = useRefSetup(() => new BehaviorSubject(0))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let setTimeCallback = (time: number): void => {
    /* noop */
  } // Overwritten later

  function setTime(newTime: number): void {
    time.next(newTime)
    setTimeCallback(newTime)
  }

  const [saving, setSaving] = useState(false)
  console.log('Rerender', {
    ...props,
    base: base.value,
    willTranscribe: willTranscribe.value,
    isPublic: isPublic.value,
    name: name.value,
    saving: saving,
  })

  const handleSaveChanges = (): void => {
    const patchObj: Partial<Meeting> = {}
    if (base.value.will_transcribe !== willTranscribe.value) {
      patchObj.will_transcribe = willTranscribe.value
    }
    if (base.value.listing_status !== newListingStatus) {
      patchObj.listing_status = newListingStatus
    }
    if (base.value.name !== name.value) {
      patchObj.name = name.value
    }
    setSaving(true)
    fetch('/api/meetings/' + base.value.id + '/', {
      method: 'PATCH',
      body: JSON.stringify(Meeting.toJsonPartial(patchObj)),
      credentials: 'same-origin',
      headers: {
        'X-CSRFToken': document.getElementById('csrf')!.textContent!,
        'Content-Type': 'application/json',
      },
    })
      .then(result => {
        if (!result.ok) throw result.statusText
        return result.json()
      })
      .then(result => base.set(Meeting.parse(result)))
      .catch(fail => alert(`Failed to save changes: ${fail}`))
      .finally(() => setSaving(false))
  }

  const newListingStatus =
    base.value.listing_status === ListingStatus.FORCED_PRIVATE
      ? ListingStatus.FORCED_PRIVATE
      : isPublic.value
      ? ListingStatus.UNLISTED
      : ListingStatus.PRIVATE

  const transcriptionMeter = document.getElementById(
    'transcription-meter'
  ) as HTMLMeterElement
  const availableTranscription =
    (transcriptionMeter.max - transcriptionMeter.value) * 1000 * 60 * 60
  let recordingLength = base.value.recording_length
  if (
    recordingLength === null &&
    base.value.start_datetime &&
    base.value.end_datetime
  ) {
    recordingLength =
      base.value.end_datetime.getTime() - base.value.start_datetime.getTime()
  }
  if (recordingLength === null) {
    recordingLength = 0
  }
  if (willTranscribe.value && recordingLength > availableTranscription) {
    willTranscribe.set(false)
  }

  return (
    <div className='matchParent d-flex flex-column'>
      <div className='d-flex flex-row'>
        <div className='d-flex flex-grow-1'>
          <label htmlFor='meetingName' className='d-flex flex-row'>
            <img className='m-3' src={editSvg} alt='Edit Title' />
            <input
              id='meetingName'
              className='bg-light border-0'
              value={name.value}
              onChange={(ev): void => name.set(ev.target.value)}
            />
          </label>
        </div>
        <button
          disabled={
            saving ||
            (base.value.will_transcribe === willTranscribe.value &&
              base.value.listing_status === newListingStatus &&
              base.value.name === name.value)
          }
          className='btn btn-primary'
          type='button'
          onClick={handleSaveChanges}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>
      {base.value.start_datetime && (
        <h4 className='meetingTime'>
          {formatDate(base.value.start_datetime!)}
        </h4>
      )}
      <div className='d-flex flex-grow-1 gap-4 video-and-notes'>
        <div className='d-flex flex-column flex-grow-1'>
          {base.value.start_transcription_datetime &&
            !base.value.end_transcription_datetime && (
              <div className='alert alert-primary fst-italic'>
                Transcription in progress
              </div>
            )}
          <MeetingNotesComponent
            meetingId={props.original.id}
            currentTime={time}
            onTimeClick={setTime}
            name={name.value}
            getCurrentTime={(): number => time.value}
            transcript={base.value.transcription}
          />
        </div>
        <div className='flex-grow-1 mw-50' style={{ minWidth: '26rem' }}>
          <label>Meeting Video</label>
          {
            <video
              className='mw-100'
              src={base.value.recording}
              controls
              ref={(vid): void => {
                if (vid) {
                  setTimeCallback = (t): number => vid && (vid.currentTime = t)
                  const hash = window.location.hash
                  const hashColon = hash.indexOf(':')
                  if (hashColon !== -1) {
                    try {
                      const parsedHashTime =
                        parseInt(hash.substring(1, hashColon)) * 60 +
                        parseInt(hash.substring(hashColon + 1))
                      vid.currentTime = parsedHashTime!
                    } catch (e) {
                      console.error(e)
                    }
                  }
                }
              }}
              onTimeUpdate={(ev): void =>
                time.next(ev.currentTarget.currentTime)
              }
            />
          }
          {!base.value.start_transcription_datetime && (
            <div className='checkbox'>
              <input
                type='checkbox'
                id='shouldTranscribe'
                disabled={recordingLength > availableTranscription}
                title={
                  recordingLength > availableTranscription
                    ? "You don't have enough transcription time left."
                    : "Transcribe the recording. It'll take a minute, so be patient."
                }
                checked={willTranscribe.value}
                onChange={(ev): void =>
                  willTranscribe.set(ev.currentTarget.checked)
                }
              />
              <label htmlFor='shouldTranscribe'>Transcribe this meeting</label>
            </div>
          )}
          {base.value.listing_status !== ListingStatus.FORCED_PRIVATE && (
            <div className='checkbox'>
              <input
                type='checkbox'
                id='shouldBePublic'
                checked={isPublic.value}
                onChange={(ev): void => isPublic.set(ev.currentTarget.checked)}
              />
              <label htmlFor='shouldBePublic'>Allow link sharing</label>
            </div>
          )}
          <a
            className='btn btn-primary'
            download='video'
            href={base.value.recording}
          >
            Download Video
          </a>
        </div>
      </div>
    </div>
  )
}

export function meetingDetail(): void {
  console.log('Setting up...')
  authenticateAws()
    .then((): void => console.log('Authenticated with AWS'))
    .catch(e => console.error(e))
  const model = Meeting.parse(
    JSON.parse(document.getElementById('model')!.textContent!)
  )
  if (model.recording) {
    render(
      <MeetingViewComponent original={model} />,
      document.getElementById('app')
    )
  } else {
    meetingCreate(model)
  }
}
