import React from 'react'
import { Observable } from 'rxjs'
import { useRx } from '../../utils/react-rxjs'
import { map } from 'rxjs/operators'
import { MeetingTextEntry } from '../../models/models'
import deleteSvg from '../../..//assets/delete.svg'

const TranscriptSection = (props: {
  label: string
  entries: Array<MeetingTextEntry>
  currentTime: Observable<number>
  onTimeClick?: (seconds: number) => void
  onRemove?: (entry: MeetingTextEntry) => void
}): React.ReactElement => {
  return (
    <div className='d-flex flex-column'>
      <h5>{props.label}</h5>
      {props.entries.map(x => (
        <TranscriptNote
          key={x.id}
          onTimeClick={props.onTimeClick}
          onRemove={props.onRemove}
          currentTime={props.currentTime}
          note={x}
        />
      ))}
    </div>
  )
}
const TranscriptNote = (props: {
  note: MeetingTextEntry
  onTimeClick?: (seconds: number) => void
  currentTime: Observable<number>
  onRemove?: (entry: MeetingTextEntry) => void
}): React.ReactElement => {
  const secondsRounded = Math.round(props.note.time_start)
  const minNum = Math.floor(secondsRounded / 60)
  const secNum = secondsRounded % 60
  let sec = secNum.toString()
  if (sec.length < 2) {
    sec = '0' + sec
  }
  const timeText = `${minNum}:${sec}`
  // const shouldHighlight = false
  const shouldHighlight = useRx(
    () =>
      props.currentTime.pipe(
        map(x => {
          return (
            props.note.time_end !== undefined &&
            props.note.time_start <= x &&
            x <= props.note.time_end
          )
        })
      ),
    false,
    [props.currentTime]
  )

  return (
    <div
      id={`transcript-note-${props.note.id}`}
      className={`d-flex transcriptNote ${shouldHighlight ? 'current' : ''}`}
    >
      <a
        className='me-3 text-decoration-underline'
        href={`#${timeText}`}
        onClick={(): void => {
          if (props.onTimeClick) {
            props.onTimeClick(props.note.time_start)
          }
        }}
      >
        {timeText}
      </a>
      <p
        className={`transcript ${
          props.note.participant === null ? 'note' : ''
        }`}
      >
        {props.note.participant === undefined
          ? `(Note: ${props.note.content})`
          : props.note.content}
      </p>
      {props.onRemove && props.note.participant === undefined && (
        <button
          className='btn small background'
          type='button'
          onClick={(): void => props.onRemove!(props.note)}
        >
          <img src={deleteSvg} alt='Delete' />
        </button>
      )}
    </div>
  )
}

export default TranscriptSection
