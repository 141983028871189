import React from 'react'
import { Participant } from '../../models/models'
import { Styleable } from '../../utils/react-extensions'
import { useRx } from '../../utils/react-rxjs'
import { Observable } from 'rxjs'

interface EntryRequestsProps extends Styleable {
  entryRequests: Observable<Array<Participant>>
  accept: (user: Participant) => Promise<Participant>
  reject: (user: Participant) => Promise<void>
}

const EntryRequests: React.FC<EntryRequestsProps> = props => {
  const { entryRequests: entryRequestsObs, accept, reject } = props
  const entryRequests = useRx(() => entryRequestsObs, [] as Array<Participant>)
  return (
    <div className={props.className} style={props.style}>
      {entryRequests.map((x, index) => (
        <div className='video-call-entry-request' key={index}>
          <div>{x.name}</div>
          <button
            className='btn btn-sm btn-primary'
            onClick={(): void => {
              accept(x)
            }}
          >
            Accept
          </button>
          <button
            className='btn btn-sm btn-primary'
            onClick={(): void => {
              reject(x)
            }}
          >
            Reject
          </button>
        </div>
      ))}
    </div>
  )
}

export default EntryRequests
