import React, { ReactElement, useState } from 'react'
import {
  ChimeJoinInfo,
  MeetingTextEntry,
  Participant,
} from '../../models/models'
import { MeetingText } from '../MeetingNotesComponent'
import { organizeBySpeaker } from '../../utils/transcript-combiner'
import { RoomControl } from '../../models/sync'
import { useRxSubscription } from '../../utils/react-rxjs'
import {map, tap} from 'rxjs/operators'
import EntryRequests from './EntryRequests'
import VideoCall from './VideoCall'
import '../../../sass/video-call.scss'
import testSound from "../../../assets/soundtest.wav";

const FinishingUpload = (): React.FC =>
  function FinishingUploadFC(): ReactElement {
    // check git history for this section of code for progress bar starter code
    return (
      <div className='video-call-state-container'>
        <h1 className='video-call-state'>Finishing upload...</h1>
      </div>
    )
  }

const MeetingOver = (): React.FC =>
  function MeetingOverFC(): ReactElement {
    return (
      <div className='video-call-state-container'>
        <h1 className='video-call-state'>Meeting&apos;s over!</h1>
      </div>
    )
  }

const MainVideoCall = (
  control: RoomControl,
  chimeDetails: ChimeJoinInfo,
  owner: boolean,
  setView: (v: React.FC) => void
) =>
  function MainVideoCallFC(): ReactElement {
    const [showChat, setShowChat] = useState(true)
    useRxSubscription(() =>
      control.meeting.pipe(map(x => x?.end_datetime)).subscribe({
        next: endTime => {
          console.log('Got end time ', endTime)
          if (endTime) {
            setView(MeetingOver())
          }
        },
      })
    )
    return (
      <div className='video-call'>
        <div className='video-call-back'>
          <VideoCall
            className='video-call-main'
            chimeDetails={chimeDetails}
            record={owner}
            onStream={(x): void => control.onStream(x)}
          />
          <div className={`video-call-chat ${showChat ? 'expanded' : ''}`}>
            <MeetingText
              submit={(msg): Promise<MeetingTextEntry> =>
                control.sendMessage(msg)
              }
              speakerNames={control.participants.pipe(
                map(x => {
                  const record: Record<string, Participant> = {}
                  for (const p of x) record[p.id] = p
                  return record
                })
              )}
              entries={control.meetingTextEntries.pipe(map(organizeBySpeaker))}
              style={{ height: "100%" }}
            />
          </div>
        </div>
        <button
          className='video-call-chat-display btn btn-sm btn-primary'
          onClick={(): void => setShowChat(!showChat)}
        >
          Chat / Notes
        </button>
        <button
          className='video-call-end btn btn-small btn-primary'
          onClick={(): void => {
            if (owner) {
              control.end().then(() => {
                setView(control.upload ? FinishingUpload() : MeetingOver())
              })
            } else {
              control.websocket.close()
              window.location.assign('../..')
            }
          }}
        >
          {owner ? 'Finish Meeting' : 'Leave Meeting'}
        </button>
        {owner && (
          <EntryRequests
            className='video-call-entry-requests'
            entryRequests={control.participants.pipe(
              map(x => x.filter(y => !y.authorized)),
              tap(x => {
                if(x.length > 0) {
                  const audio = new Audio(testSound)
                  audio.play()
                }
              })
            )}
            accept={(u): Promise<Participant> => control.authorize(u)}
            reject={(u): Promise<void> => control.kick(u)}
          />
        )}
      </div>
    )
  }

export default MainVideoCall
