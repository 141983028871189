import {
    Meeting,
MeetingTextEntry,
MeetingTextEntrySet,
Participant,
SummarizePrompt,
SummarizeResult,
} from '../../models/models' 
import { useRx } from '../../utils/react-rxjs'
import { BehaviorSubject, Observable } from "rxjs"
import { Styleable, useRefSetup } from '../../utils/react-extensions'
import React, { useEffect, useState } from 'react'
import { parse } from 'uuid'
import { xhrCallback } from '@sentry/tracing/dist/browser/request'
import TextareaAutosize from 'react-textarea-autosize';


export interface MeetingSummaryProps extends Styleable{
entries: Observable<Array<MeetingTextEntrySet>>
meetingId: number
}

const MeetingSummary: React.FC<MeetingSummaryProps> = props => {
const entries = useRx(() => props.entries, [], [props.entries])
const [aiPrompts, setAiPrompts] = useState(new Array<SummarizePrompt>())
const [selectedPrompt, setSelectedPrompt] = useState<SummarizePrompt|null>(null)
const [summary, setSummary] = useState("")
const [loading, setLoading] = useState(false)
const [error, setError] = useState<string | null>(null)
const [summaryChanged, setSummaryChanged] = useState(false)
const [confirmNewSummary, setConfirmNewSummary] = useState(false)

const [meeting, setMeeting] = useState<Meeting | null>(null)

    useEffect(() => {
        fetch(`/api/ai-summarize/`,{
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
          }).then(x => x.json())
          .then((prompts: Array<SummarizePrompt>) => {
              setAiPrompts(prompts)
                setSelectedPrompt(prompts[0])
          })
    }, [])


    useEffect(() => {
        fetch(`/api/meetings/${props.meetingId}/`,{
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
          }).then(x => x.json())
          .then((meeting: Meeting) => {
                setMeeting(meeting)
            if (meeting.summaries.length > 0)
              setSummary(meeting.summaries[meeting.summaries.length-1].result)
          })
    }, [props.meetingId])
    
const getSummary = () =>{
    if (aiPrompts.length === 0 ) {
        window.location.href = "/ai-summarize-prompts" 
        return
    }
    setLoading(true)
    setError(null)
    const wholeTranscript = entries.map((entry)=>entry.entries.map((entry2)=>entry2.content).join(" ")).join(" ")
    fetch(`/ai-summarize-prompts/ai-summarize/`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken':
            document.getElementById('csrf')!.textContent!,
        },
        credentials: 'same-origin',
        body: JSON.stringify({
            promptId: selectedPrompt?.id,
            text: wholeTranscript,
            resourceId: props.meetingId,
            resourceType: "meeting",
        }),
    }).then(response => {
        return response.json()})
    .then((jsonResponse: {"summary":string,"error":string}) => {
        if (typeof jsonResponse.summary !== 'string'){
            setError(jsonResponse.error ?jsonResponse.error:"Error getting summary from AI. Please try again.")
            setLoading(false)
            return 
        }
        setSummary(jsonResponse.summary)
        setLoading(false)
    }).catch((error: any) => {
        setLoading(false)
        console.log("error 1", error.response.error)
        setError("Error getting summary from AI. Please try again.")
        console.log("error", error)
    })
}


const updateSummary = () => {
    fetch(`/ai-summarize-prompts/update-summary/`,{
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken':
            document.getElementById('csrf')!.textContent!,
        },
        credentials: 'same-origin',
        body: JSON.stringify({
            resourceId: props.meetingId,
            summary: summary,
            resourceType: "meeting",
        }),
        }).then(response => {
            return response.json()})
        .then((response:{"success":Boolean, 'summary':SummarizeResult[]}) => {
            if (response.success){
                setSummaryChanged(false)
                setError(null)
                setMeeting((prevMeeting) => {
                    if (prevMeeting === null)
                        return null
                    prevMeeting.summaries = response.summary
                    return prevMeeting
                })
            }
            else{
                setError("Error updating summary. Please try again.")
            }
        }).catch((error: any) => {
            setError("Error updating summary. Please try again.")
            console.log("error", error)
        })
}

const handleSelectChange = (event: any)=> {
    if (aiPrompts.length === 0 ) {
        return
    }
    const promptId = event.target.value
    const prompt = aiPrompts.find((element) => element.id === parseInt(promptId))
    setSelectedPrompt(prompt??null)

}

return (
    <div className={`meeting-text d-flex flex-column p-3 ${
        props.className || ''
        }`}
        style={props.style}>
                <h2>Summary</h2>

            <label className="d-flex flex-row" >
            <svg className="m-3" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path fill="#AAA" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
        </svg>
        <TextareaAutosize minRows={1} value={summary} className="bg-light border-0" name="collection-summary" placeholder="Collection Summary..." onChange={(event)=>{
            if (meeting?.summaries[meeting.summaries.length-1].result !== event.target.value){
                setSummaryChanged(true)
                setSummary(event.target.value)
            }
            else
                setSummaryChanged(false)
        }}/>   
            </label>
            {summaryChanged &&
            <button className="btn btn-primary" onClick={updateSummary}>Update Summary</button>
            }
            <label >Prompt Preview</label>
                <select id="selected-prompt" onChange={handleSelectChange} className="btn-select btn btn-outline">
                    <option placeholder='Select Ai Prompt' key='Select Ai Prompt' value='Select Ai Prompt'> Select Ai Prompt</option>
                    {aiPrompts.map((element, index) => (
                        <option placeholder='Select Ai Prompt' key={element.name} value={element.id} selected={index === 0}>
                            {element.name}
                        </option>
                    ))}
                </select>

            <label className="d-flex flex-row" >
            {/* <svg className="m-3" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path fill="#AAA" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
        </svg> */}
                <TextareaAutosize value={selectedPrompt?.prompt??""} minRows={1} id="prompt-text-preview"  name="prompt-text-preview" className="bg-light border-0" placeholder="Prompt text..."/>
            </label>
                {loading ? (  <button className="btn btn-primary align-center">
                    <div className="small-loader-spinner"></div>
                    </button>): (
                <button className="btn btn-primary" onClick={()=>{
                    if (meeting?.summaries?.length ??0 > 0) {
                        const lastSummary = meeting?.summaries[meeting.summaries.length-1]
                        console.log("lastSummary", lastSummary)
                        if (lastSummary?.edited) {
                            setConfirmNewSummary(true)
                            return
                        } 
                    } 
                    getSummary()
                
                }}>Get Summary</button>
                    )}
            {error && 
            <p className="text-danger">{error}</p>
            }
            {confirmNewSummary &&
            <div className="dialog-overlay">
            <dialog>
                <div className='flexVertical'>
                    <h3>Previously edited the Summary</h3>
                    <p>
                        You have previously edited the summary. Do you want to get a new summary?
                    </p>
                    <div className="d-flex flex-row">
                    <button
                        className='btn btn-primary mr-2'
                        onClick={(): void => {
                            setConfirmNewSummary(false)
                        }}
                    >
                        Keep Summary
                    </button>
                    <button
                        className='btn btn-primary '
                        onClick={(): void => {
                            getSummary()
                            setConfirmNewSummary(false)
                        }}
                    >
                        Get New Summary
                    </button>

                    </div>
                </div>
            </dialog>
            </div>
            }
    </div>
            
)
}

export default MeetingSummary