import {
  useAudioVideo,
  useActiveSpeakersState,
} from 'amazon-chime-sdk-component-library-react'
import { AudioVideoObserver } from 'amazon-chime-sdk-js'
import { useRefSetup } from '../../utils/react-extensions'
import { StreamMerger } from '../../utils/stream-merge'
import React, { useEffect } from 'react'

function useMergedStream(): MediaStream | null {
  const avn = useAudioVideo()
  const speakers = useActiveSpeakersState()
  const stream = useRefSetup(() => new StreamMerger())

  function refresh(): void {
    const av = avn!
    stream.updateStreams(
      [
        // prettier-ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ((av as DefaultAudioVideoFacade).audioMixController as DefaultAudioMixController).audioStream,
        // prettier-ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (((av as DefaultAudioVideoFacade).deviceController as DeviceController).activeDevices['audio'] as DeviceSelection)?.stream ?? null as MediaStream | null,
      ].filter(x => x !== null),
      av.getAllVideoTiles().map(x => x.state()),
      speakers
    )
  }

  useEffect(() => {
    const obs: AudioVideoObserver = {
      videoTileDidUpdate: () => refresh(),
      videoTileWasRemoved: () => refresh(),
    }
    avn?.addObserver(obs)
    return (): void => avn?.removeObserver(obs)
  }, [avn])
  if (avn !== null) refresh()
  return stream.result
}

const VideoRecorder: React.FC<{
  onStream: (m: MediaStream) => void
}> = props => {
  console.log("Video recorder hit!")
  const stream = useMergedStream()
  if (stream) props.onStream(stream)
  return null
}

export default VideoRecorder
