import React from 'react'
import {NEVER, Observable} from 'rxjs'
import { OngoingRecording } from '../../utils/recording'
import MeetingActivelyRecordingComponent from './MeetingActivelyRecordingComponent'
import MeetingFinishingComponent from './MeetingFinishingComponent'
import MeetingStartRecordComponent from './MeetingStartRecordComponent'
import {VideoUploading} from "../../utils/aws-upload";
import {useRx} from "../../utils/react-rxjs";
import MeetingFinishedComponent from "./MeetingFinishedComponent";

const MeetingRecordComponent: React.FC<{
  stream: MediaStream
  upload: VideoUploading | null
  recording: OngoingRecording | null
  startRecording: (r: OngoingRecording) => void
  finishRecording: () => void
}> = props => {
  const fullVideoUrl = useRx(() => props.upload?.fullUrl ?? NEVER, undefined, [props.upload])
  const stream = props.stream
  if (fullVideoUrl && props.upload) {
    return (<MeetingFinishedComponent id={"asdf"} upload={props.upload}/>)
  } else if (props.recording != null && props.upload != null) {
    return (
      <MeetingActivelyRecordingComponent
        stream={stream}
        recording={props.recording}
        upload={props.upload}
        onFinish={props.finishRecording}
      />
    )
  } else if (props.upload != null) {
    //Show upload progress
    return <MeetingFinishingComponent upload={props.upload} />
  } else {
    //Show start button
    return (
      <MeetingStartRecordComponent
        stream={stream}
        onStart={props.startRecording}
      />
    )
  }
}

export default MeetingRecordComponent
