import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { DependencyList, useEffect, useState } from 'react'
import { MutableObservableProperty } from './rxjs-extensions'
import { useRefSetup } from './react-extensions'

export function useRx<T>(
  observable: () => Observable<T>,
  startingValue: T,
  deps?: Array<any>
): T
export function useRx<A, B>(
  observable: () => Observable<A>,
  startingValue: B,
  deps: Array<any> = []
): A | B {
  const [obs] = useState(observable)
  const [current, setter] = useState<A | B>(startingValue)
  useEffect(() => {
    const sub = obs.subscribe(x => {
      setter(x)
    })
    return (): void => {
      window.setTimeout(() => {
        sub.unsubscribe()
      })
    }
  }, deps)
  return current
}

export function useRxOld<T>(
  observable: Observable<T>,
  startingValue: T,
  label = 'an observable'
): T {
  const [obs] = useState(observable)
  const [current, setter] = useState<T>(startingValue)
  useEffect(() => {
    // console.log("Subscribing to ", label)
    const sub = obs.subscribe(x => {
      // console.log("Got ", x, " for ", label)
      setter(x)
    })
    return (): void => {
      window.setTimeout(() => {
        // console.log("Unsubscribing from ", label)
        sub.unsubscribe()
      })
    }
  }, [])
  return current
}

export function useRxSubscription(
  makeSub: () => Subscription,
  deps?: DependencyList
): void {
  useEffect(() => {
    const sub = makeSub()
    return (): void => {
      sub.unsubscribe()
    }
  }, deps)
}

export function useStateSubject<T>(
  defaultMaker: () => T
): MutableObservableProperty<T> {
  const subject = useRefSetup(() => new BehaviorSubject(defaultMaker()))
  const [, setValue] = useState(defaultMaker())
  useEffect(() => {
    const sub = subject.subscribe(x => {
      setValue(x)
    })
    return (): void => {
      window.setTimeout(() => {
        sub.unsubscribe()
      })
    }
  }, [])
  return subject
}
