import { Observable, NextObserver, BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'

export function notNull<T>(): (
  o: Observable<T | null | undefined>
) => Observable<T> {
  return filter(x => x !== null && x !== undefined) as (
    o: Observable<T | null>
  ) => Observable<T>
}

export function xBehaviorSubject<T>(
  value: T,
  setter: (value: T) => void
): BehaviorSubject<T> {
  const sub = new BehaviorSubject(value)
  sub.subscribe(setter)
  return sub
}

export type MutableObservable<T> = Observable<T> & NextObserver<T>
export interface ObservableProperty<T> extends Observable<T> {
  value: T
}
export type MutableObservableProperty<T> = MutableObservable<T> &
  ObservableProperty<T>
